var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.hasPermission)?_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('mex-heading',{attrs:{"content":"Rental License View"}})],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"2"}},[_c('v-menu',{ref:"startDateMenu",attrs:{"close-on-content-click":false,"return-value":_vm.selectedBackupRetrievalDate,"min-width":"auto","offset-y":"","transition":"scale-transition"},on:{"update:returnValue":function($event){_vm.selectedBackupRetrievalDate=$event},"update:return-value":function($event){_vm.selectedBackupRetrievalDate=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"dense":"","hide-details":"","label":"Backup Date","outlined":"","readonly":""},model:{value:(_vm.selectedBackupRetrievalDate),callback:function ($$v) {_vm.selectedBackupRetrievalDate=$$v},expression:"selectedBackupRetrievalDate"}},'v-text-field',attrs,false),on))]}}],null,false,791415114),model:{value:(_vm.startDateMenu),callback:function ($$v) {_vm.startDateMenu=$$v},expression:"startDateMenu"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":"","allowed-dates":_vm.isBackupDateGiven},on:{"change":function($event){return _vm.backupDateChanged(_vm.selectedBackupRetrievalDate)}},model:{value:(_vm.selectedBackupRetrievalDate),callback:function ($$v) {_vm.selectedBackupRetrievalDate=$$v},expression:"selectedBackupRetrievalDate"}})],1)],1),_c('v-col',{attrs:{"cols":"1"}},[_c('mex-btn',{attrs:{"icon":"mdi-close","icon-only":"","tooltip":"Reset Backup-Date to current time"},on:{"click":function($event){_vm.selectedBackupRetrievalDate = null}}})],1),_c('v-col',{attrs:{"cols":"1"}},[_c('mex-btn',{attrs:{"icon":"mdi-file-download-outline","content":".csv","tooltip":"Download CSV-file with Rental License Overview"},on:{"click":function($event){return _vm.downloadFile('csv')}}})],1),_c('v-col',{attrs:{"cols":"1"}},[_c('mex-btn',{attrs:{"icon":"mdi-file-download-outline","content":".json","tooltip":"Download JSON-file with Rental License Overview"},on:{"click":function($event){return _vm.downloadFile('json')}}})],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('mex-data-table',{attrs:{"data":_vm.locationCycleOverview,"headers":_vm.locationCycleOverviewHeaders,"isLoading":_vm.locationCycleOverviewLoading,"loadingText":"Loading Rental License Overview ...","item-key":"LocationID","single-select":"","tableClass":"foreground","disable-pagination":"true","footer-props":{
          'items-per-page-options': [1000, 1500, 2000, 2500, 3000]
        }},scopedSlots:_vm._u([{key:"item.thresholdMinMax",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.threshold.minValue + ' - ' + item.threshold.maxValue)+" ")]}},{key:"item.reportedCycleTypes",fn:function(ref){
        var item = ref.item;
return [_c('v-chip-group',[_vm._l((item.cycleTypes),function(cycleType){return [_c('v-chip',{attrs:{"x-small":""}},[_vm._v(_vm._s(cycleType.name + ' (' + cycleType.usedCyclesOfLocation + ')'))]),_c('br')]})],2)]}},{key:"item.customerNumber",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.customerNumber || '-')+" ")]}},{key:"item.groupStatus",fn:function(ref){
        var item = ref.item;
return [(item.usedCyclesSum >= item.threshold.minValue && item.usedCyclesSum <= item.threshold.maxValue)?_c('mex-status-lamp',{attrs:{"color":"success"}}):_c('mex-status-lamp',{attrs:{"color":"error"}})]}}],null,false,946285940)})],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }