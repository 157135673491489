<template>
  <v-container v-if="hasPermission" fluid>
    <v-row justify="center">
      <v-col cols="12">
        <mex-heading content="Rental License View" />
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="2">
        <v-menu
          ref="startDateMenu"
          v-model="startDateMenu"
          :close-on-content-click="false"
          :return-value.sync="selectedBackupRetrievalDate"
          min-width="auto"
          offset-y
          transition="scale-transition"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field v-model="selectedBackupRetrievalDate" v-bind="attrs" v-on="on" dense hide-details label="Backup Date" outlined readonly></v-text-field>
          </template>
          <v-date-picker
            v-model="selectedBackupRetrievalDate"
            no-title
            scrollable
            :allowed-dates="isBackupDateGiven"
            @change="backupDateChanged(selectedBackupRetrievalDate)"
          >
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="1">
        <mex-btn icon="mdi-close" icon-only @click="selectedBackupRetrievalDate = null" tooltip="Reset Backup-Date to current time"></mex-btn>
      </v-col>
      <v-col cols="1">
        <mex-btn icon="mdi-file-download-outline" content=".csv" @click="downloadFile('csv')" tooltip="Download CSV-file with Rental License Overview"></mex-btn>
      </v-col>
      <v-col cols="1">
        <mex-btn icon="mdi-file-download-outline" content=".json" @click="downloadFile('json')" tooltip="Download JSON-file with Rental License Overview"></mex-btn>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12">
        <mex-data-table
          :data="locationCycleOverview"
          :headers="locationCycleOverviewHeaders"
          :isLoading="locationCycleOverviewLoading"
          loadingText="Loading Rental License Overview ..."
          item-key="LocationID"
          single-select
          tableClass="foreground"
          disable-pagination="true"
          :footer-props="{
            'items-per-page-options': [1000, 1500, 2000, 2500, 3000]
          }"
        >
          <template v-slot:item.thresholdMinMax="{ item }">
            {{ item.threshold.minValue + ' - ' + item.threshold.maxValue }}
          </template>
          <template v-slot:item.reportedCycleTypes="{ item }">
            <v-chip-group>
              <template v-for="cycleType in item.cycleTypes">
                <v-chip x-small>{{ cycleType.name + ' (' + cycleType.usedCyclesOfLocation + ')'}}</v-chip>
                <br/>
              </template>
            </v-chip-group>
          </template>
          <template v-slot:item.customerNumber="{ item }">
            {{ item.customerNumber || '-' }}
          </template>
          <template v-slot:item.groupStatus="{ item }">
            <mex-status-lamp
              v-if="item.usedCyclesSum >= item.threshold.minValue && item.usedCyclesSum <= item.threshold.maxValue"
              color="success"
            />
            <mex-status-lamp v-else color="error" />
          </template>
        </mex-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import requiredPermissions from '../../requiredPermissions';
import CycleGroupsService from "../../services/cycleGroups.service";
import tablePagination from "../../config/tablePagination.config";
import MexDataTable from "../../components/MedITEX_Vue_Components/MexComponents/MexDataTable";
import MexBtn from "../../components/MedITEX_Vue_Components/MexComponents/MexButton";
import {assignSeveralProperties} from "../../functions/assignPropertyIfExists";
import OrganizationsService from "../../services/organizations.service";

export default {
  name: 'RentalLicenseView',
  components: { MexBtn, MexDataTable },
  computed: {
    ...mapGetters('sysAuth', ['getUserPermissions']),
    getTablePagination() {
      return tablePagination;
    }
  },
  data() {
    return {
      // pagination data
      actPage: 1,
      actSize: tablePagination.defaultRowsPerPage,
      totalItems: 0,
      totalPages: 0,

      // cycle overview
      locationCycleOverviewHeaders: [
        {
          text: 'Organization-Name',
          sortable: true,
          value: 'organizationName',
        },
        {
          text: 'Clinic-Name',
          sortable: true,
          value: 'clinicName',
        },
        {
          text: 'Location-Name',
          sortable: true,
          value: 'locationName',
        },
        {
          text: 'Customer-Number',
          sortable: true,
          value: 'customerNumber',
        },
        {
          text: 'Template-Title',
          sortable: true,
          value: 'templateTitle',
        },
        {
          text: 'Threshold',
          sortable: false,
          value: 'thresholdMinMax',
        },
        {
          text: 'Cycle-Types',
          sortable: false,
          value: 'reportedCycleTypes',
        },
        {
          text: 'Used Cycle-Types (sum)',
          sortable: true,
          value: 'usedCyclesSum',
        },
        {
          text: 'Group-State',
          sortable: false,
          value: 'groupStatus',
        },
      ],
      locationCycleOverview: [],
      locationCycleOverviewLoading: false,
      availableBackupDates: [],
      selectedBackupRetrievalDate: null,
      // selection data
      selectedOrganization: null,
      selectedClinic: null,
      organizationNames: [],
      clinicNames: [],
      preSelection: {
        clinic: null,
        organization: null,
      },
      hasPermission: false,
      startDateMenu: null,
    };
  },
  watch: {
    selectedOrganization: {
      handler() {
        this.fetchClinicNames();
        this.$store.commit('selectedProperties/setRentalLicenseViewOrganization', this.selectedOrganization);
      },
    },
    selectedBackupRetrievalDate: {
      handler() {
        this.selectedBackupRetrievalDate ? this.loadLocationCycleOverviewFromBackup(this.selectedBackupRetrievalDate) : this.getRentalLicenseOverview();
      }
    }
  },
  created() {
    this.$userPermissions.fetchCurrentUserPermissions(requiredPermissions.rentalLicense, this.$store)
      .then((hasPermission) => {
        if (hasPermission) {
          this.hasPermission = true;
          this.getRentalLicenseOverview(this.actPage, this.actSize);
          this.getAvailableBackupDates();
          this.fetchOrganizationNames();
          assignSeveralProperties([this.$route.params, this.$route.query], ['organization', 'clinic'], this.preSelection, this.$store, 'rentalLicenseView');
        } else {
          this.$router.push({ name: "NotFound" });
        }
      })
      .catch((err) => {
        this.$router.push({ name: "NotFound" });
      });
  },
  beforeRouteLeave(to, from, next) {
    if (this.editMode && !this.showSaveRequest) {
      this.showSaveRequest = true;
    } else {
      next();
    }
  },
  methods: {
    /**
     * turns a string like this: "IVF (3) | Not performed (20) | GIFT (31) | Diag. cycle (21)" (received from backup-file-route)
     * into an array like this (which can be processed in the table):
     * [
     *   { name: 'IVF', usedCyclesOfLocation: 3 },
     *   { name: 'Not performed', usedCyclesOfLocation: 20 },
     *   { name: 'GIFT', usedCyclesOfLocation: 31 },
     *   { name: 'Diag. cycle', usedCyclesOfLocation: 21 },
     * ]
     *
     * @param cycleString
     * @return {*[]}
     */
    parseCycles(cycleString) {
      const cycles = cycleString.split(' | ');
      let result = [];

      cycles.forEach((cycle) => {
        const match = cycle.match(/(.+)\s\((\d+)\)/);
        if (match) {
          result.push({
            name: match[1],
            usedCyclesOfLocation: parseInt(match[2], 10),
          });
        }
      });

      return result;
    },
    /**
     * turns the object received from this.loadLocationCycleOverviewFromBackup() (the backup-file as an object) and turns it into
     * a for the frontend computable and displayable object
     *
     * @param backupObject
     * @return {{}}
     */
    turnBackupInProperObject(backupObject) {
      let result = {};
      for (const key in backupObject) {
        if (key === 'Threshold') {
          const [minValue, maxValue] = backupObject['Threshold'].split(" - ").map(Number);
          result['threshold'] = { minValue: minValue, maxValue: maxValue };
        } else if (key === 'Cycle-Types') {
          result['cycleTypes'] = this.parseCycles(backupObject[key]);
        }else {
          // turn keys like "Organization-Name", "Clinic-Name", "Customer-Number" to "organizationName", "clinicName", "customerNumber"
          const keyInCamelCase = key.split('-').map((part, index) => index === 0 ? part[0].toLowerCase() + part.slice(1) : part).join('');
          result[keyInCamelCase] = backupObject[key];
        }
      }
      result['usedCyclesSum'] = result['cycleTypes'].map(cycle => cycle.usedCyclesOfLocation).reduce((a, b) => a + b);
      return result;
    },
    loadLocationCycleOverviewFromBackup(date) {
      CycleGroupsService.getLocationCycleOverviewFromBackup(date)
        .then((locationCycleOverviewResponse) => {
          this.locationCycleOverview = JSON.parse(locationCycleOverviewResponse.data.locationCycleOverview).map(instance => this.turnBackupInProperObject(instance));
        })
        .catch((err) => {
          this.$toast.error('Problem while loading overview from date ' + date + ': ' + err.message);
        });
    },
    backupDateChanged(date) {
      this.$refs.startDateMenu.save(date);
    },
    isBackupDateGiven(date) {
      return this.availableBackupDates.includes(date);
    },
    fetchOrganizationNames() {
      if (this.organizationNames.length === 0) {
        OrganizationsService.getOrganizationNames()
          .then((organizationResponse) => {
            organizationResponse.data.forEach((orga) => {
              this.organizationNames.push({
                value: orga.OrganizationID,
                text: orga.name,
              });
            });
            if (this.preSelection.organization) {
              this.selectedOrganization = this.preSelection.organization;
              this.preSelection.organization = null;
            } else {
              this.selectedOrganization = null;
            }
          })
          .catch((err) => {
            this.$toast.error(err);
          });
      }
    },
    getAvailableBackupDates() {
      CycleGroupsService.getAvailableBackupDates()
        .then((response) => {
          this.availableBackupDates = [...response.data.availableBackupDates];
        })
        .catch((err) => {
          this.$toast.error('Problem while loading available backup-dates');
        });
    },
    downloadCycleGroupsFile(content, filename, fileType) {
      const blob = new Blob([content], { type: fileType });

      const a = document.createElement('a');
      a.download = filename;
      a.href = URL.createObjectURL(blob);
      a.dataset.downloadurl = [fileType, a.download, a.href].join(':');
      a.style.display = 'none';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      setTimeout(function revokeDownloadURL() {
        URL.revokeObjectURL(a.href);
      }, 10000);
    },
    downloadFile(fileType) {
      CycleGroupsService.getCycleTypeGroups(this.selectedBackupRetrievalDate, fileType)
        .then((response) => {
          this.downloadCycleGroupsFile(response.data.cycleTypeGroupsFile, response.data.filename, 'text/' + fileType);
          this.$toast.success('Downloading "RentalLicenseOverview.' + fileType + '"');
        })
        .catch((err) => {
          this.$toast.error('Problem while loading cycle-type-overview in ' + fileType + '-format');
        });
    },
    goToLocationView(id) {
      this.$router.push({ name: 'LocationView', params: { id } });
    },
    goToClinicView(id) {
      this.$router.push({ name: 'ClinicView', params: { id: id } });
    },
    allGroupsInThreshold(location) {
      for (let i = 0; i < location.CycleGroups.length; i++) {
        if (location.CycleGroups[i].usedCycles < location.CycleGroups[i].CycleThresholdCategory.minValue
          || location.CycleGroups[i].usedCycles > location.CycleGroups[i].CycleThresholdCategory.maxValue)
        {
          return false;
        }
      }
      return true;
    },
    getRentalLicenseOverview() {
      this.locationCycleOverviewLoading = true;
      CycleGroupsService.getLocationCycleOverview()
        .then((response) => {
          this.locationCycleOverview = [];
          response.data.locationCycleOverview.forEach((location) => {
            location.CycleGroups.forEach((cycleGroup) => {
              this.locationCycleOverview.push({
                'organizationName': location.Clinic.Organization.name,
                'clinicName': location.Clinic.name,
                'locationName': location.name,
                'customerNumber': location.Clinic.customerNumber,
                'templateTitle': cycleGroup.CycleGroupTemplate.name,
                'threshold': cycleGroup.CycleThresholdCategory,
                'cycleTypes': cycleGroup.CycleGroupTemplate.CycleTypeGroups.map(cycleTypeGroup => cycleTypeGroup.CycleType),
                'usedCyclesSum': cycleGroup.CycleGroupTemplate.CycleTypeGroups.map(cycleTypeGroup => cycleTypeGroup.CycleType.usedCyclesOfLocation).reduce((a, b) => a + b)
              });
            });
          });
          this.locationCycleOverviewLoading = false;
        })
        .catch((err) => {
          this.locationCycleOverviewLoading = false;
          this.$toast.error('Problem while loading rental-license-view');
        });
    },
  },
};
</script>

<style></style>
